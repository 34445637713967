import React, { useState } from 'react';
import { SpeakerphoneIcon, XIcon } from '@heroicons/react/outline';
import { useStaticQuery, graphql, Link } from 'gatsby';
import { RichText } from 'prismic-reactjs';
import htmlSerializer from '../utils/htmlSerializer';

const Banner = () => {
  const data = useStaticQuery(graphql`
    query AnnouncementQuery {
      prismicBannerAnnouncement {
        data {
          show_announcement
          content {
            raw
          }
        }
      }
    }
  `);

  const announcementText = RichText.asText(
    data.prismicBannerAnnouncement.data.content.raw
  );
  const showAnnouncement =
    data.prismicBannerAnnouncement.data.show_announcement;

  const [isOpen, setIsOpen] = useState(true);

  let content = null;

  if (isOpen && showAnnouncement) {
    content = (
      <div className='bg-primary'>
        <div className='mx-auto max-w-7xl py-3 px-3 sm:px-6 lg:px-8'>
          <div className='flex flex-wrap items-center justify-between'>
            <div className='flex w-0 flex-1 items-center'>
              <span className='flex rounded-lg bg-primary p-2'>
                <SpeakerphoneIcon
                  className='h-6 w-6 text-white'
                  aria-hidden='true'
                />
              </span>
              <p className='ml-3 font-medium text-white'>
                <div className='md:hidden'>
                  <RichText
                    render={data.prismicBannerAnnouncement.data.content.raw}
                    htmlSerializer={htmlSerializer}
                  />
                </div>
                <div className='hidden md:inline'>
                  <RichText
                    render={data.prismicBannerAnnouncement.data.content.raw}
                    htmlSerializer={htmlSerializer}
                  />
                </div>
              </p>
            </div>
            <div className='order-2 flex-shrink-0 sm:order-3 sm:ml-3'>
              <button
                type='button'
                className='hover:bg-darkslategray -mr-1 flex rounded-md p-2 focus:outline-none focus:ring-2 focus:ring-white sm:-mr-2'
                onClick={() => setIsOpen(!isOpen)}
              >
                <span className='sr-only'>Dismiss</span>
                <XIcon className='h-6 w-6 text-white' aria-hidden='true' />
              </button>
            </div>
          </div>
        </div>
      </div>
    );
  }
  return content;
};

export default Banner;
