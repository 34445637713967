import { RichText } from 'prismic-reactjs';
import React from 'react';
import htmlSerializer from '../utils/htmlSerializer';
import Image from './image';

const AboutTheSharpener = ({ title, details, image, imageAlt }) => {
  const smallScreenComponent = (
    <div className='flex w-full flex-col items-center justify-between px-8 md:hidden'>
      <Image image={image} alt={imageAlt} className='rounded' />
      <div className='text-lg'>
        <RichText render={details.raw} htmlSerializer={htmlSerializer} />
      </div>
    </div>
  );

  const largeScreenComponent = (
    <div className='hidden w-full items-center justify-between md:flex md:flex-col'>
      <div className='px-4 text-justify text-lg'>
        <RichText render={details.raw} htmlSerializer={htmlSerializer} />
      </div>
      <Image image={image} alt={imageAlt} className='w-2/5 rounded shadow-lg' />
    </div>
  );

  return (
    <div
      id='about the sharpener'
      className='flex w-full flex-col items-center bg-primary text-gray-100'
    >
      <div className='w-full py-12 md:w-2/3'>
        <div className='p-8 text-center md:px-0'>
          <p className='text-2xl font-semibold md:text-3xl'>{title}</p>
        </div>
        {smallScreenComponent}
        {largeScreenComponent}
      </div>
    </div>
  );
};

export default AboutTheSharpener;
