import React from 'react';
import Image from './image';

const KnifeRepair = ({ title, examples }) => {
  return (
    <div className='mb-12 flex w-full flex-col items-center bg-black py-20'>
      <div className='w-full md:w-2/3'>
        <div className='px-8 text-center text-gray-100 md:px-0'>
          <p className='text-3xl font-semibold'>{title}</p>
        </div>
        <div className='flex flex-col items-center justify-around px-8 md:flex-row md:flex-wrap md:px-0'>
          {examples.map((example, index) => {
            const { repair_image, description } = example;
            return (
              <div
                key={index}
                className='md:flexBasis50 my-4 text-center md:m-4'
                style={{ minHeight: `15rem` }}
              >
                {description.raw.length > 0 ? (
                  <span className='italic text-gray-100 md:max-w-xs md:py-4'>
                    {description.raw[0].text}
                  </span>
                ) : null}
                <div className='max-w-sm transform py-4 text-center transition-transform hover:scale-110 md:py-0'>
                  <Image
                    image={repair_image.gatsbyImageData}
                    alt={repair_image.alt}
                    className='rounded'
                  />
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default KnifeRepair;
