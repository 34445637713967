import React from 'react';

import { graphql, Link } from 'gatsby';
import { SliceZone } from '../sliceZone';
import SEO from '../components/seo';
import AboutTheSharpener from '../components/aboutTheSharpener';
import KnifeRepair from '../components/knifeRepair';
import Pricing from '../components/slices/pricing';
import CallToAction from '../components/callToAction';
import RbrsGCal from '../components/rbrsGCal';
import NewNavbar from '../components/navbar/newNavbar';
import Footer from '../components/footer';
import { RichText } from 'prismic-reactjs';
import Image from '../components/image';
import Banner from '../components/banner';

const IndexPage = ({ data }) => {
  const site = data.site;
  const homePageData = data.prismicHomePage.data;

  const callToActionHeroUrl = homePageData.call_to_action_hero.url;

  const { call_to_action_label } = homePageData;

  const companyInfoData = data.prismicCompanyLogo.data;

  const pricing = {
    pricingTitle: RichText.asText(homePageData.pricing_title.raw),
    pricingSubtitle: RichText.asText(homePageData.pricing_subtitle.raw),
    ...homePageData.body1.find((b) => b.slice_type === 'pricing_cards'),
  };

  const taglineText = RichText.asText(homePageData.tagline.raw);
  const secondaryTaglineText = RichText.asText(
    homePageData.secondary_tagline.raw
  );
  const taglineHighlight = homePageData.tagline_highlight;

  return (
    <>
      <SEO
        site={site}
        title='Sharpening Knives, Shears, Sewing tools and more.'
      />
      <Banner />
      <div className='relative overflow-hidden bg-white'>
        <div className='mx-auto max-w-7xl'>
          <div className='relative z-10 bg-white pb-8 sm:pb-16 md:pb-20 lg:w-full lg:max-w-2xl lg:pb-28 xl:pb-32'>
            <svg
              className='absolute inset-y-0 right-0 hidden h-full w-48 translate-x-1/2 transform text-white lg:block'
              fill='currentColor'
              viewBox='0 0 100 100'
              preserveAspectRatio='none'
              aria-hidden='true'
            >
              <polygon points='50,0 100,0 50,100 0,100' />
            </svg>

            <NewNavbar />
            <main className='mx-auto mt-10 max-w-7xl px-4 sm:mt-12 sm:px-6 md:mt-16 lg:mt-20 lg:px-8 xl:mt-28'>
              <div className='sm:text-center lg:text-left'>
                <h1 className='text-4xl font-extrabold tracking-tight text-gray-900 sm:text-5xl md:text-6xl'>
                  <span className='block xl:inline'>
                    {taglineText}{' '}
                    <span className='block text-primary xl:inline'>
                      {/* {taglineHighlight} */}
                    </span>
                  </span>{' '}
                </h1>
                <p className='mt-3 text-base text-gray-500 sm:mx-auto sm:mt-5 sm:max-w-xl sm:text-lg md:mt-5 md:text-xl lg:mx-0'>
                  <span className='font-semibold text-primary'>
                    {companyInfoData.company_name}
                  </span>{' '}
                  {/* {secondaryTaglineText} */}
                </p>
                <div className='mt-5 sm:mt-8 sm:flex sm:justify-center lg:justify-start'>
                  <div className='rounded-md shadow'>
                    <Link
                      to={callToActionHeroUrl}
                      className='flex w-full items-center justify-center rounded-md border border-transparent bg-primary px-8 py-3 text-base font-medium text-white hover:bg-hover md:py-4 md:px-10 md:text-lg'
                    >
                      Learn More
                    </Link>
                  </div>
                </div>
              </div>
            </main>
          </div>
        </div>
        <div className='lg:absolute lg:inset-y-0 lg:right-0 lg:w-2/3'>
          <Image
            image={homePageData.hero_background_image.gatsbyImageData}
            alt='hero background'
            className='h-56 w-full object-cover sm:h-72 md:h-96 lg:h-full lg:w-full'
          />
        </div>
      </div>
      <div className='mx-auto flex flex-col items-center'>
        <RbrsGCal />
        <SliceZone
          slices={homePageData.body1.filter(
            (b) => b.slice_type !== 'call-to-action'
          )}
        />
        <KnifeRepair
          title={homePageData.secondary_section_title.raw[0].text}
          examples={homePageData.repair_group}
        />
        <Pricing pricing={pricing} />
        <AboutTheSharpener
          title={homePageData.section_title.raw[0].text}
          details={homePageData.details}
          image={homePageData.section_image.gatsbyImageData}
          imageAlt={homePageData.section_image.alt}
        ></AboutTheSharpener>
        <CallToAction to={callToActionHeroUrl} label={call_to_action_label} />
      </div>
      <Footer />
    </>
  );
};

export const Index2PageQuery = graphql`
  query IndexPageQuery {
    site {
      siteMetadata {
        title
        description
        author
      }
    }
    prismicHomePage {
      data {
        call_to_action_hero {
          url
        }
        call_to_action_label
        call_to_action_anchor_text
        tagline {
          raw
        }
        tagline_highlight
        secondary_tagline {
          raw
        }
        body1 {
          ... on PrismicHomePageDataBody1AlternateGrid {
            primary {
              description {
                raw
              }
              image_side
              title {
                raw
              }
              optional_image {
                alt
                gatsbyImageData
              }
            }
            slice_type
            items {
              title {
                raw
              }
              optional_icon {
                alt
                gatsbyImageData(width: 200)
              }
              description {
                raw
              }
            }
          }
          ... on PrismicHomePageDataBody1CallToAction {
            slice_type
            primary {
              call_to_action_label
              call_to_action_link {
                url
              }
            }
          }
          ... on PrismicHomePageDataBody1EventSchedule {
            slice_type
            primary {
              title {
                raw
              }
              link {
                url
              }
              description {
                raw
              }
            }
          }
          ... on PrismicHomePageDataBody1PricingCards {
            slice_type
            items {
              product_details {
                raw
              }
              product_price {
                raw
              }
              product_title {
                raw
              }
            }
          }
        }
        pricing_title {
          raw
        }
        pricing_subtitle {
          raw
        }
        details {
          raw
        }
        secondary_section_title {
          raw
        }
        repair_group {
          repair_image {
            alt
            gatsbyImageData(width: 500)
          }
          description {
            raw
          }
        }
        hero_background_image {
          gatsbyImageData
        }
        section_image {
          alt
          gatsbyImageData
        }
        section_title {
          raw
        }
        tagline {
          raw
        }
      }
    }
    prismicCompanyLogo {
      data {
        company_name
      }
    }
  }
`;

export default IndexPage;
