import React from 'react';

const Pricing = ({ pricing }) => {
  const { pricingTitle, pricingSubtitle, items } = pricing;

  return (
    <div
      id={pricingTitle.toLowerCase()}
      className='flex w-full flex-col items-center'
    >
      <div className='w-full py-12 md:w-2/3'>
        <div className='flex flex-col items-center justify-center px-8 py-4 md:px-0 md:py-8'>
          <p className='text-2xl font-semibold text-primary md:text-3xl'>
            {pricingTitle}
          </p>
          <span className='text-center italic text-gray-600 md:text-lg'>
            {pricingSubtitle}
          </span>
        </div>
        <div className='flex flex-col items-center justify-center px-8 md:flex-row md:flex-wrap md:px-0'>
          {items.map((item, index) => {
            const { product_title, product_price, product_details } = item;
            return (
              <div
                key={index}
                className='m-4 flex w-4/5 flex-col items-center justify-center rounded border-t-2 border-primary px-4 pb-8 pt-6 text-center shadow-lg md:w-1/4 lg:h-36 giant:h-36'
              >
                <p className='text-2xl font-semibold text-primary'>
                  {product_title.raw[0].text}
                </p>
                <p>{product_price.raw[0].text}</p>
                {product_details.raw.length > 0 ? (
                  <p className='text-gray-600'>{product_details.raw[0].text}</p>
                ) : null}
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default Pricing;
